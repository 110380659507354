import logo from './logo.svg';
import './App.css';
import NavNar from './component/Navbar/NavBar';
import Programs from './component/Program/Programs';
import Footer from './component/Footer/Footer';
import Home from './component/Home/Home';
import Contact from './component/Contact/Contacts';

function App() {
  return (
    <div className="App">
      <NavNar/>
      <Home/>
      <Programs/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
