import React from 'react';
import PlayRadio from './PlayRadio.jsx';
import './Navbar.css'
import logo from '../../images/logo.jpeg'
import { Link } from 'react-scroll';

const NavNar = () => {
  return (

      <div className='n-wrapper' id='Navbar'>

        <div className="n-left">
        <img src={logo} className='logo'/>
          <div className="n-list">
            
            <ul>
              <li>
                <Link  to='Home'>
                Home
                </Link>
              </li>
              <li>
                <Link to='Programs'>
                Programs
                </Link>
              </li>
              <li>
                <Link>
                TV
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="n-right">
          <div className="">
            <PlayRadio/>
          </div>
        </div>
      </div>
      
  );
}

export default NavNar;