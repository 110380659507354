import React, { useRef, useState, useContext } from 'react'
import './Contact.css'
import emailjs from '@emailjs/browser';

const Contact = () => {

    const form = useRef();
    const [done, setDone] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_9qgy64t', 'template_56da22y', form.current, 'B1HtRAHtASkwifUbB')
      .then((result) => {
          console.log(result.text);
          setDone(true)
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div className="contact-form" id="contact">
      {/* left side copy and paste from work section */}
      <div className="w-left">
        <div className="awesome">
        <div className="blur contact-blur"></div>
          
          <div className="head-quaters">
            <span>Headquaters:</span>
            <span>Pajo House, 1st FLoor,</span>
            <span>Kitui, Kenya</span>
          </div>

          <div className="contacts">
            <div className="text">
                <span className='first'>Email: </span><span>info@kamulahtvradio.co.ke</span>
            </div>
            <div className="text">
            <span className='first'>SMS Code: </span><span>33201</span>
            </div>
            <div className="text">
            <span className='first'>Reception Phone: </span><span>0722000000</span>
            </div>
            <div className="text"> 
            <span className='first'>Studio Lines: </span><span>0722000000</span>
            </div>
          </div>
        </div>
      </div>
      {/* right side form */}
      <div className="c-right">
          <span className='right-t stroke-text'>Get in Touch</span>
          <span className='right-t'>Contact me</span>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="user_name" className="user"  placeholder="Name"/>
          <input type="email" name="user_email" className="user" placeholder="Email"/>
          <textarea name="message" className="user" placeholder="Message"/>
          <input type="submit" value="Send" className="button"/>
          <span>{done && "Thanks for Contacting me"}</span>
          <div
            className="blur c-blur1"
            style={{ background: "var(--purple)" }}
          ></div>
        </form>
      </div>
    </div>
  )
}

export default Contact