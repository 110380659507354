import React from 'react'
import './Home.css'
import logo from '../../images/logo.jpeg'
import { Swiper, SwiperSlide } from "swiper/react"
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectFade, Pagination, Navigation } from 'swiper/modules';
const Home = () => {
  return (
    <div className="home" id='Home'>
        <Swiper
        spaceBetween={30}
        effect={'fade'}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[EffectFade, Navigation, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={logo} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logo} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logo} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logo} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logo} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logo} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Home