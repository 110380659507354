import Plyr from 'plyr'
function PlayRadio() {
    const player = new Plyr('audio', {});

// Expose player so it can be used from the console
      window.player = player;

    
    return (
        <div className="">
            
            <div className='audioPlay'>
          <audio id="player" controls>
            <source src="https://stream.zeno.fm/p0fulzmnnkdvv" type="audio/mp3" />
          </audio>
        </div>
        </div>
    )
}

export default PlayRadio