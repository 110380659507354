import React, {useEffect, useState
} from "react"
import './Program.css'
import axios from "axios"
function Programs(){
    const [data, setData] = useState([])

    useEffect(() =>{
        axios.get('https://kamulah-radio-api.onrender.com/programs')
        .then(res => {
            console.log(res)
            setData(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    })
    return(
        
        <>
        
        <div className="programs" id='Programs'>
        
           <div className="p-left">
            <table striped bordered hover>
              <thead>
                <tr>
                  <th className="header">Day</th>
                  <th className="header">Program</th>
                  <th className="header">Time</th>
          
                </tr>
              </thead>
        
                <tbody className="table-body">
                {data.map((item) => (
                  <tr key={item.name} className="t-data">
                    <td className="data day">{item.day}</td>
                    <td className="data program">{item.title}</td>
                    <td className="data time">{item.startTime} - {item.endTime}</td>
                    
                  </tr>
                  ))}        
                
                </tbody>
       
      </table>
      </div>
           <div className="p-right">
            <div className="blur programs-blur"></div>
            <div className="other-ervices">
              <span>Other Services</span>
              <ul className="service-d">
                <li>Quality livestreaming and video coverage in all events</li>
                <li>Weeding planner</li>
                <li>Recording and Video Production</li>
                <li>Cyber services</li>
                <li>Quidance and Counseling </li>
              </ul>
            </div>
           </div>
        </div>
        

        </>
    )
}
export default Programs